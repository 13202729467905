<template>
    <div class="page-container">
  
      <!-- The warning modal will only be visible if we try to transfer some material that are currently labeled as "in use" -->
      <confirm-modal @cancel="toggleWarningModal" @yes="transferCSV" :visible="warningModal.isVisible" :toggle="toggleWarningModal">
        <p>{{ $t('pages.export_csv.warningModal.mainMsg') }}</p>
        <p class="mt-2 italic"><i class="fas fa-exclamation-triangle mr-2 text-yellow-400"></i>{{ $t('pages.export_csv.warningModal.warningMsg') }}</p>
      </confirm-modal>
  
  
      <div class="h-full flex flex-col pb-14">
        <!-- Top bar (users / scan) -->
        <div class="flex mb-5">
            <!-- From user -->
            <div class="w-full flex">
                <label class="mr-2 mt-2">{{ $t('pages.export_csv.top.from') }}: </label>
                <!-- loader -->
                <span v-if="!users.isLoaded">
                    <i class="mt-3 fas fa-spinner fa-spin"></i>
                </span>
                  <!-- Select from user -->
                <multiselect v-else v-model="selection.fromUser" @select="fromUserOnSelected" track-by="id"
                                :custom-label="userLabelDisplayed"
                                :placeholder="$t('pages.export_csv.top.selectUser')"
                                :options="users.selectOptions"
                                group-label="role"
                                group-values="users"
                                :searchable="true"
                                :allow-empty="false"
                                :disabled="this.$store.getters.userIsFarmer"
                                :showLabels="false">
                    <template slot="noResult">{{ $t('pages.export_csv.top.noResult') }}</template>
                </multiselect>
            </div>

            <div class="w-full flex"></div>

            <div class="w-full flex text-right">
                <label class="mr-2 mt-2">{{ $t('pages.export_csv.top.to') }}: </label>
                <!-- loader -->
                <span v-if="!culture.isLoaded">
                    <i class="mt-3 fas fa-spinner fa-spin"></i>
                </span>
                <!-- Select from user -->
                <multiselect v-else v-model="selection.culture" @select="fromCultureOnSelected" track-by="id"
                                :custom-label="cultureLabelDisplayed"
                                :placeholder="$t('pages.export_csv.top.selectCulture')"
                                :options="culture.selectOptions"
                                group-label="use"
                                group-values="culture"
                                :searchable="true"
                                :allow-empty="true"
                                :disabled="inventory.inLoad"
                                :showLabels="false">
                    <template slot="noResult">{{ $t('pages.export_csv.top.noResult') }}</template>
                </multiselect>
            </div>
        </div>
  
        <!-- Transfer part -->
        <div class="flex h-full">
          <!-- Left col (From inventory) -->
          <div class="transfer-card pb-16">
            <div class="h-full">
              <div v-if="!inventory.isLoaded" class="h-full text-center flex flex-col justify-center ">
                <div v-if="selection.fromUser === null" class="font-light text-xl">
                  {{ $t('pages.export_csv.inventory.selectUserInfo') }}
                </div>
                <div v-else-if="selection.culture === null" class="font-light text-xl">
                  {{ $t('pages.export_csv.inventory.cultureUserInfo') }}
                </div>
                <div v-else-if="!inventory.isLoaded" class="text-4xl text-gray-600">
                  <i class="fas fa-spinner fa-spin"></i>
                </div>
              </div>
              <div v-else class="h-full">
                <div v-if="inventory.data.length === 0" class="h-full text-center flex flex-col justify-center font-light text-xl text-center">
                  {{ $t('pages.export_csv.inventory.noMaterial') }}
                </div>
                <div v-else class="h-full">
                  <!-- Tool bar (filter / search) -->
                  <div class="pt-4 pb-2.5 text-center">
                    <!-- Search -->
                    <div class="w-full">
                      <input v-model="selection.searchTerm" type="text" class="search-input" :placeholder="$t('pages.export_csv.inventory.searchPlaceholder')">
                    </div>
                  </div>
                  <!-- Inventory content -->
                  <div class="max-h-full w-full overflow-y-auto">
                    <div class="flex flex-col px-2">
                      <!-- No search results -->
                      <div v-if="filteredItems.length === 0" class="h-full text-center flex flex-col justify-center font-light text-xl text-center mt-14">
                        <span>{{ $t('pages.export_csv.inventory.searchNoResult', {term: selection.searchTerm}) }}</span>
                      </div>
                      <!-- Search result OK -->
                      <div v-else v-for="item in filteredItems" :key="item.id + item.type" @click="toggleSelectedItem(item)" class="inventory-item flex" :class="{'item--selected': item.selected}">
                        <span class="w-full">{{ item.id }} - <span class="font-light">{{ $t("backend_trans_keys."+item.typeTransKey) }}</span></span>
                        <span class="w-1/3">{{ $t('pages.export_csv.inventory.' + item.type) }}</span>
                        <span class="w-1/3 text-right">
                          <span class="item__status" :class="[item.used ? 'item__status--used' : 'item__status--free']">{{ item.used ? $t('pages.export_csv.inventory.statusUsed') : $t('pages.export_csv.inventory.statusFree') }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Middle col (Arrow) -->
          <div class="min-h-full flex flex-col justify-center w-1/5">
            <div class="text-center">
              <i class="text-gray-600 fas fa-arrow-right text-5xl"></i>
            </div>
          </div>
  
          <!-- Right col (Materials that will be transferred) -->
          <div class="transfer-card relative pb-32">
  
            <!-- Title -->
            <div class="text-center mt-4 mb-1.5">
              <h1 class="text-xl font-normal">{{ $t('pages.export_csv.transferZone.titleExportData', {count: selectedItems.length}) }}</h1>
            </div>
  
            <!-- Materials that will be transferred -->
            <div class="max-h-full w-full overflow-y-auto">
              <div class="flex flex-col px-2">
                <div v-for="item in selectedItems" :key="item.id + item.type" @click="toggleSelectedItem(item)" class="inventory-item item--unselected flex">
                  <span class="w-full">{{ item.id }} - <span class="font-light">{{ $t("backend_trans_keys."+item.typeTransKey) }}</span></span>
                  <span class="w-1/3">{{ $t('pages.export_csv.inventory.' + item.type) }}</span>
                  <span class="w-1/3 text-right">
                    <span class="item__status" :class="[item.used ? 'item__status--used' : 'item__status--free']">{{ item.used ? $t('pages.export_csv.inventory.statusUsed') : $t('pages.export_csv.inventory.statusFree') }}</span>
                  </span>
                </div>
              </div>
            </div>
  
            <!-- Transfer button -->
            <div class="absolute bottom-6 w-full">
              <div v-if="selectedItems.length > 0">
                <div v-if="selection.toUser === null" class="text-center text-red-500 font-semibold">
                  <span>{{ $t('pages.export_csv.transferZone.errorNoDestUser') }}</span>
                </div>
                <div v-else class="mx-auto flex justify-center">
                  <button @click="onClickTransfer" class="w-1/2 btn btn--success text-xl -ml-10" :disabled="loadingCSV">
                    <span v-if="loadingCSV">
                      <i class="mr-2 fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-else>
                      <i class="fas fa-check mr-2"></i>{{ $t('pages.export_csv.transferZone.validate') }}
                    </span>
                  </button>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {apiGetRequest,generateDownloadCsvLink,apiGetRequestArray} from "@/utils";
  import {
    API_HISTORY_FARMERS_CULTURES,
    API_USERS_ALL,
    API_USERS_SPECIFIC_SHOW,
    API_HISTORY_CULTURES_TRANSMITTERS_SENSORS,
    API_HISTORY_FARMERS_CULTURES_SERIES,
    API_HISTORY_SERIES_CSV_EXPORT,
    API_CULTURES_SHOW2,
  } from "../../../utils/constants";
  import Multiselect from "vue-multiselect";
  import ConfirmModal from "../../../components/modals/confirm-modal";
import { Promise } from "core-js";
  
  export default {
    name: "ExportCSV",
    components: {Multiselect, ConfirmModal},
    data: function() {
      return {
        users: {
          isLoaded: false,
          // Will be used to generate all groups in the html select group
          optGroups:{
            admins: this.$t('pages.export_csv.top.admins'),
            farmers: this.$t('pages.export_csv.top.farmers')
          },
          data: null,
          selectOptions: null // The data that will be used in the vue-multiselect component
        },
        culture: {
          isLoaded: true,
          optGroups:{
            use: this.$t('pages.export_csv.top.use'),
            archived: this.$t('pages.export_csv.top.archived')
          },
          data: null, // Real array that will contain all material (inventory) of the selected user
          selectOptions: [] // The data that will be used in the vue-multiselect component
        },
        transmitter: {
          id : []
        },
        inventory: {
          isLoaded: false,
          inLoad: false,
          SensorPromise: [],
          IdToExport : [],
          tmp: [], // buffer of the array that will contain all material (inventory) of the selected user
          data: [], // Real array that will contain all material (inventory) of the selected user
          filtered: [], // The array that will be really displayed to the screen, it will change depends on the filter, search etc... (but references same object as the inventory array)
          types: {
            transmitter: "TRANSMITTER",
            sensor: "SENSOR"
          }
        },
        selection: {
          fromUser: null,
          culture : null,
          sensor  : null,
          searchTerm: '',
          scanValue: null
        },
        scanError: {
          display: false,
          msg: null
        },
        warningModal: {
          isVisible: false
        },
        loadingCSV: false
      }
    },
    async mounted() {
      if(this.$store.getters.userIsAdmin){
        this.loadUsers()
        this.culture.selectOptions = []
      }else if(this.$store.getters.userIsFarmer ) {
        await this.loadMe()
        this.fromUserOnSelected(this.users.selectOptions[0].users[0])
      }
      this.culture.selectOptions = []
      this.culture.isLoaded = true
      
    },
    computed: {
      /**
       * Return all selected items
       * @returns {*[]|*}  An array containing all selected items or an empty array
       */
      selectedItems: function() {
        console.log("update")
        return this.inventory.data === null ? [] : this.inventory.data.filter(item => item.selected)
      },
      /**
       * Return a filtered array according the current search term typed
       * @returns A filtered array according the current search term typed
       */
      filteredItems: function() {
        return this.inventory.filtered.filter((item) => {
          let searchTermLower = this.selection.searchTerm.toLowerCase()
          return (
              // Searching in ID
              item.id.toString().includes(this.selection.searchTerm) ||
              // Searching in real type (dendrometer, ...)
              this.$t('backend_trans_keys.' + item.typeTransKey).toLowerCase().includes(searchTermLower) ||
              // Searching by general type (transmitter or sensor)
              this.$t('pages.export_csv.inventory.' + item.type).toLowerCase().includes(searchTermLower) ||
              // Searching by "free" or "used"
              (item.used && this.$t('pages.export_csv.inventory.statusUsed').toLowerCase().includes(searchTermLower)) ||
              (!item.used && this.$t('pages.export_csv.inventory.statusFree').toLowerCase().includes(searchTermLower)))
        })
      }
    },
    methods: {
      /**
       * Method used to display a custom label in the dropdown list for <select> fromUser and toUser
       */
      userLabelDisplayed ({ firstname, lastname, email }) {
        
        return `${firstname} ${lastname} [${email}]`
      },
      /**
       * Callback when we select a user in the fromUser select
       * @param selectedUser The selected user
       */
      fromUserOnSelected: function(selectedUser) {
        
        this.inventory.isLoaded = false
        this.inventory.data = []
        this.inventory.filtered = []
        this.selection.culture = null
        this.selection.fromUser = selectedUser
        this.loadCulture()
      },
      cultureLabelDisplayed ({ name }) {
        return `${name}`
      },
      /**
       * Callback when we select a user in the fromUser select
       * @param selectedUser The selected user
       */
      fromCultureOnSelected: function(selectedCulture) {
        this.selection.culture = selectedCulture
        this.inventory.inLoad = true
        this.loadInventoryTransmitter()
      },
      /**
       * Callback when we select a user in the toUser select
       * @param selectedUser The selected user
       */
      toUserOnSelected: function(selectedUser) {
        this.selection.toUser = selectedUser
      },
      /**
       * Load all users available
       */
      loadUsers: function(){
        this.users.isLoaded = false
        apiGetRequest(API_USERS_ALL)
            .then((res) => {
              this.users.data = res.data.farmers

              // Generate the formatted selectOptions array to respect what is asked by the Vue-Multiselect lib.
              let selectOptions = []
              let usersArray = []
              for(let userType in this.users.data){
                let user = this.users.data[userType]
                usersArray.push({ id: user.id, firstname: user.firstname, lastname: user.lastname, email: user.email})
              }
              selectOptions.push({
              role: this.users.optGroups.farmers,
              users: usersArray
              })
              this.users.selectOptions = selectOptions
              this.users.isLoaded = true
            })
      },
      loadMe: async function(){
        this.users.isLoaded = false        
        let id = this.$store.state.user.id
        await apiGetRequest(API_USERS_SPECIFIC_SHOW(id))
            .then((res) => {
              let selectOptions = []
              let usersArray = res.data
              this.users.data = [{ id: id, firstname: usersArray.firstname, lastname: usersArray.lastname, email: usersArray.email}]
              selectOptions.push({
                role: this.users.optGroups.farmers,
                users: [{ id: id, firstname: usersArray.firstname, lastname: usersArray.lastname, email: usersArray.email}]
                })
              this.users.selectOptions = selectOptions
            })
        this.users.isLoaded = true
      },
      /**
       * Load the inventory of the current selected user
       */
      loadCulture: function(){
        // Back to zero
        this.culture.isLoaded = false
        this.culture.data = []
        // Send all our requests and wait on them
        apiGetRequest(API_HISTORY_FARMERS_CULTURES(this.selection.fromUser.id))
        .then((res) => {
            this.culture.data = res.data
            let selectOptions = []
            let CultureArrayOnGoing = []
            let CultureArrayArchived = []
            console.log("culture")
            for(let culture_index in this.culture.data){
              if (this.culture.data[culture_index].archived)
              {
                CultureArrayArchived.push(this.culture.data[culture_index])
              }
              else
              {
                CultureArrayOnGoing.push(this.culture.data[culture_index])
              }
            }
            console.log("selectOptions",selectOptions)
            selectOptions.push({
              use: this.culture.optGroups["use"],
              culture: CultureArrayOnGoing
            })
            selectOptions.push({
              use: this.culture.optGroups["archived"],
              culture: CultureArrayArchived
            })
            console.log("selectOptions",selectOptions)
            this.culture.selectOptions = selectOptions
            this.culture.isLoaded = true
        })
      },
      /**
       * Load the inventory of the current selected user
       */
      loadInventoryTransmitter: function() {
        // Back to zero
        this.inventory.SensorPromise = []
        this.inventory.isLoaded = false
        this.inventory.data = []
        this.inventory.tmp = []
        this.inventory.filtered = []
        // Send all our requests and wait on them
        if(this.selection.culture.archived)
        {
          this.loadArchivedInventory();
          setTimeout(this.ArchivedloadInventorySensor,1500)
        }
        else
        {
          this.loadOngoingInventory();
          setTimeout(this.OngoingloadInventorySensor,1500)
        }        
      },

      /**
       * Load the Sensor of the curent culture
       */
      loadOngoingInventory: function() {
        this.inventory.SensorPromise = []
        apiGetRequest(API_CULTURES_SHOW2(this.selection.culture.id))
          .then((res) => {
            this.selectedCulture = res.data
            //Get all transmitter 
            let list_transmiter = this.selectedCulture.transmitters.map((t) => {
              t.type = this.inventory.types.transmitter
              t.selected = false
              return t
            })
            console.log("list_transmiter : " ,list_transmiter)
            //for all transmitter find each special type (flowmeter,weatherStation)
            for(let transmitter in list_transmiter)
            {
              console.log("transmitter : " ,transmitter)
              if(list_transmiter[transmitter].flowMeter || list_transmiter[transmitter].weatherStation)
              {
                this.inventory.tmp.push(list_transmiter[transmitter])
              }
              else
              {
                //get all sensor not linked to a special transmiter 
                this.inventory.SensorPromise.push(apiGetRequest(API_HISTORY_CULTURES_TRANSMITTERS_SENSORS(this.selection.culture.id,list_transmiter[transmitter].id)))
              }
            }
            this.inventory.SensorPromise.push(apiGetRequest(API_HISTORY_FARMERS_CULTURES_SERIES(this.selection.fromUser.id,this.selection.culture.id)))
            console.log("this.inventory.SensorPromise : " ,this.inventory.SensorPromise)
          })     
      },
      /**
       * Load the Sensor of the curent culture
       */
      loadArchivedInventory: function() {
        this.inventory.SensorPromise = []
        this.inventory.SensorPromise.push(apiGetRequest(API_HISTORY_FARMERS_CULTURES_SERIES(this.selection.fromUser.id,this.selection.culture.id)))
      },
      /**
       * Load the Sensor of the curent culture
       */
      OngoingloadInventorySensor: function() {
        // Back to zero
        this.inventory.isLoaded = false
        this.selection.searchTerm = ''
        if(this.inventory.SensorPromise.length === 0)
        { 
          this.inventory.isLoaded = true
          
          return null;
        }
        console.log("this.inventory.SensorPromise : " ,this.inventory.SensorPromise)
        // Send all our requests and wait on them
        Promise.all(this.inventory.SensorPromise)
        .then((res) => {
          let data = []
          console.log("res.data : " ,res)
            let i = 0
            while(i < res.length)
            { 
              console.log("show i : " ,i)
              console.log("res[i] : " ,res[i])
              for (let array of res[i].data) {
                console.log("array : " ,array)
                if(array.id === undefined)
                {
                  console.log("undefined " ,array.id)
                  console.log("array.sensorIds[0] " ,array.sensorIds[0])
                  console.log("array.sensorIds[0] " ,array)
                  array.id = array.sensorIds[0]
                }
                if(array.type == undefined)
                {
                  array.type = this.inventory.types.sensor
                }
                if(array.typeTransKey == undefined)
                {
                  array.typeTransKey = array.sensorTypeTransKey
                } 
                array.selected = false
                data = data.concat(array);
              }
              i++
            }
          
          console.log("data " ,data)

          //link old measure with sensors 
          for(let item of data)
          {
            let index = this.inventory.tmp.findIndex(itemList => item.id === itemList.id)
            let indexTransmit = -1
            try{
              indexTransmit = this.inventory.tmp.findIndex(itemList => item.transmitterIds[0] === itemList.id)
            }
            catch{indexTransmit = -1}
            console.log("index i : " ,index," , ", indexTransmit)
            if (indexTransmit != -1)
            {
              console.log("contain " ,item.id)
              try{
                this.inventory.tmp[indexTransmit].measureUuid.push(item.measureUuid)
              }
              catch{
                this.inventory.tmp[indexTransmit].measureUuid = [item.measureUuid]
              }
            }
            else if(index != -1)
            {
              try{
                this.inventory.tmp[index].measureUuid.push(item.measureUuid)
              }
              catch{
                this.inventory.tmp[index].measureUuid = [item.measureUuid]
              }
            }
            else
            {
              this.inventory.tmp.push(item)
            }
          }
          this.inventory.data = this.inventory.data.concat(this.inventory.tmp)
          this.inventory.data.sort(this.sortInventoryByIdASC)
          this.inventory.filtered = [...this.inventory.data]
          console.log("this.inventory.filtered ",this.inventory.filtered)
          this.inventory.isLoaded = true
          this.inventory.inLoad = false
        })
      },
      /**
       * Load the Sensor of the archived culture
       */
       ArchivedloadInventorySensor: function() {
        // Back to zero
        this.inventory.isLoaded = false
        this.selection.searchTerm = ''
        if(this.inventory.SensorPromise.length === 0)
        { 
          this.inventory.isLoaded = true
          return null;
        }
        // Send all our requests and wait on them
        Promise.all(this.inventory.SensorPromise)
        .then((res) => {
          let tmpsensor = res[0].data.map(s => {
            s.type = this.inventory.types.sensor
            s.typeTransKey = s.sensorTypeTransKey
            s.id = s.sensorIds[0]
            s.selected = false
            return s
          })
          let sensors = []
          for(let sensor of tmpsensor)
          {
            let index = sensors.findIndex(itemList => sensor.id === itemList.id)
            if(index != -1)
            {
              try{
                sensors[index].measureUuid.push(sensor.measureUuid)
              }
              catch{
                sensors[index].measureUuid = [sensor.measureUuid]
              }
            }
            else
            {
              sensors.push(sensor)
            }
          }
          console.log(sensors)
          this.inventory.data =  this.inventory.data.concat(sensors)
          this.inventory.data.sort(this.sortInventoryByIdASC)
          this.inventory.filtered = [...this.inventory.data]
          this.inventory.isLoaded = true
          this.inventory.inLoad = false
        })
      },
      /**
       * Toggle the selected properties of a specific item
       * @param item The item that has been clicked on
       */
      toggleSelectedItem: function(item){
        console.log("toogle")
        console.log("item before ",item)
        item.selected = !item.selected
        console.log("this.selectedItems ",this.selectedItems)
      },
      sortInventoryByIdASC: function(a, b){
        if(a.id === b.id){
          return 0
        }else if(a.id < b.id){
          return -1
        }else{
          return 1
        }
      },
      /**
       * Scan a code that we typed in the scan input. Two methods to entering a code :
       * 1. Manually type a code in the scan input and press enter or click on the + button.
       *    Accepted format : T or S (transmitter or sensor) follows by the material ID. We may add a dash (-) between letter and ID for more visibility.
       *    Example: T1234, T-1234, S1234, S-1234
       * 2. Automatically scan a bar code with bar code scanner (need to have the focus on the input)
       *    Accepted format: A barcode that contains T or S (transmitter or sensor) follows by the material ID.
       *    Example: T1234, S1234
       */
      scanCode: function(){
        this.cleanScanError()
  
        // We let the possibility to manually type the code with a '-' between the first letter (T | S) and the number for more clarity
        // So we remove this unwanted character for our logic
        const trimValue = this.selection.scanValue.replace('-', '')
  
        // Extract the first char to now if we search for a Transmitter (T) or a Sensor (S)
        const prefix = trimValue.substring(0,1)
        let materialTypeWanted = null
        if(prefix === 'T'){
          materialTypeWanted = this.inventory.types.transmitter
        }else if(prefix === 'S') {
          materialTypeWanted = this.inventory.types.sensor
        }else{ // Wrong letter
          this.displayScanError(this.$t('pages.export_csv.top.scan.errors.firstLetter'))
          return
        }
  
        // Fetch the ID part and cast it as an integer
        const idWanted = parseInt(trimValue.substring(1))
  
        // Search the item in the full loaded inventory and toggle it
        let scannedItem = this.inventory.data.find((item) => item.id === idWanted && item.type === materialTypeWanted)
        if(scannedItem === undefined){ // Wrong id
          this.displayScanError(this.$t('pages.export_csv.top.scan.errors.notFound'))
          return
        }
        this.toggleSelectedItem(scannedItem)
  
        // Reset value
        this.selection.scanValue = null
      },
      displayScanError: function(msg) {
        this.scanError.msg = msg
        this.scanError.display = true
      },
      cleanScanError: function() {
        this.scanError.display = false
        this.scanError.msg = null
      },
      /**
       * Called when we click on the transfer button.
       * We check if there is at least one selected item that is labeled as currently in used. If this is the case, we display
       * a warning modal. Otherwise we transfer all the material
       */
      onClickTransfer: function() {
        let usedItemFound = this.inventory.data.filter((item) => item.selected)
        if(usedItemFound == undefined){
          this.toggleWarningModal()
        }else{
          if(!this.loadingCSV){
            this.loadingCSV = true
            this.GenerateIdFromExport();
            setTimeout(this.ExportCSV,100)
          }
        }
      },
      GenerateIdFromExport: function() {
        this.inventory.IdToExport = []
        let usedItemFound = this.inventory.data.filter((item) => item.selected)
        this.inventory.IdToExport = usedItemFound.map(a => a.measureUuid)
        console.log(this.inventory.IdToExport)
      },

      ExportCSV: function() {
        let PromiseExport = []
        console.log("ExportCSV::"+this.inventory.IdToExport.join(","))
        PromiseExport = apiGetRequestArray(API_HISTORY_SERIES_CSV_EXPORT(this.inventory.IdToExport))
          console.log("Promise",PromiseExport)
          PromiseExport.then((res) => {
                const link = generateDownloadCsvLink(res.data, res.headers['x-filename'])
                link.click();
              })
              .finally(() => this.loadingCSV = false)
       },
       toggleWarningModal: function() {
      this.warningModal.isVisible = !this.warningModal.isVisible
      }
    }
  }
  </script>
  
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  
  <style scoped>
  .page-container {
    height: calc(100vh - 136px); /* need this way to have full height space available */
  }
  .transfer-card {
    @apply bg-white shadow-md rounded-md px-4 w-full;
  }
  .user-select {
    @apply px-3 py-1 border border-gray-300 rounded-md cursor-pointer focus:outline-none focus:ring focus:ring-green-500 focus:border-green-600;
  }
  .quick-add-input {
    @apply w-60 py-2 pl-2 pr-12;
  }
  .quick-add-input__classic{
    @apply border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-500 focus:border-green-600;
  }
  .quick-add-input__error{
    @apply border-2 border-red-500 rounded-md focus:outline-none;
  }
  .quick-add-button {
    @apply -ml-10 py-2 px-3 text-white bg-green-500 border border-green-600 rounded-tr-md rounded-br-md hover:bg-green-600;
  }
  .search-input {
    @apply w-1/2 px-2 py-0.5 border border-gray-300 rounded-sm focus:outline-none focus:ring focus:ring-green-500 focus:border-green-600;
  }
  
  .inventory-item {
    @apply border rounded-md px-4 py-2 my-2 cursor-pointer;
  }
  .inventory-item:hover {
    box-shadow: 0 0 0 2px #059669;
  }
  .item--selected {
    @apply bg-green-500 text-white;
  }
  .item__status {
    @apply px-2 py-1 font-semibold text-white text-sm rounded-md border;
  }
  .item__status--free {
    @apply bg-green-400 border-green-600;
  }
  .item__status--used {
    @apply bg-red-400 border-red-600;
  }
  
  </style>
  
  <style>
  .multiselect__content-wrapper{
    @apply shadow-lg; /* to improve the visibility of the dropdown list above the item list */
  }
  </style>
  